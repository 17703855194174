import React from 'react';
import moment from 'moment';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { StatisticsBlock } from '../components/V2/StatisticsBlock/StatisticsBlock';
import { HighlightBlockNoImage } from '../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { LogoGrid } from '../components/V2/LogoGrid/LogoGrid';
import { FooterButtonOverlay } from '../components/V2/FooterButtonOverlay/FooterButtonOverlay';
import { ClientSpotlight } from '../components/V2/ClientSpotlight/ClientSpotlight';
import { AnchoredContent } from '../components/V2/AnchoredContent/AnchoredContent';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';

import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { BackgroundCircles, BackgroundGrey, BackgroundHalfGrey } from './../GlobalStyles.jsx';

const Dental = () => {
  return (
    <PageContainer>
      <HelmetContainer
        image="/assets/images/dental/dental.png"
        currentPage={'dental'}
        title="Dental Lab Software | Oqcam"
        description="Powered by advanced AI technology, Oqcam’s dental lab software automates digital production workflows for all dental applications."
      />
      <Header />

      <LargeHero
        BackgroundStyle={'image'}
        BackgroundColour={'off-white'}
        Copy={
          '<h1><b>Dental Lab Software</b><p>Powered by advanced AI technology, Oqcam’s dental lab software automates digital production workflows for all dental applications. <br/><br/>Boost your efficiency and start saving valuable time and money with Oqcam today.</p>'
        }
        Background={'/assets/images/dental/dental.png'}
        Button={{
          SourceID: 'request-trial',
          copy: 'Request a free trial',
          OpenDemoModel: true
        }}
      />

      <AnnoucementBar Image={'/assets/images/thankyou/thankyou.png'} Content={'Oqton Dental has become Oqcam! We have completed a buy-out process, and we are now a new company called Oqcam.'} Link={{label:'Read more', link:'https://www.linkedin.com/feed/update/urn:li:activity:7202333114512101377'}}></AnnoucementBar>

      <ClientSpotlight
        Title={'Our clients in the spotlight'}
        Items={[
          {
            Logo: '/assets/images/industry-partners/3DRPD.png',
            Copy: '“Oqcam is a gamechanger – it already helped increasing our productivity by 30%. We fit more parts per build, while still reducing print times. And we significantly reduced data preparation.”',
            Person: '- Eric Fortin, VP Production'
          },
          {
            Logo: '/assets/images/industry-partners/bertram.png',
            Copy: '“Oqcam consistently maintains the highest level of quality that has enabled us to increase efficiency and scalability exponentially.”',
            Person: '- Andy Timblin, CAD/CAM Production Supervisor'
          },
          {
            Logo: '/assets/images/industry-partners/zyx.png',
            Copy: '“Since we started using Oqcam, we have reduced the data preparation time our technicians need to set up the machines by 65%, which has meant an extraordinary improvement in terms of time and quality”',
            Person: '- Juan Carlos, Production Director'
          }
        ]}
      />
      <BackgroundCircles>
        <StatisticsBlock
          Items={[
            {
              order: 'row',
              description: 'faster data preparation from intelligent automation',
              lines: [
                {
                  copy: 'Up to',
                  type: ''
                },
                {
                  copy: '98%',
                  type: 'bold'
                }
              ]
            },
            {
              order: 'row',
              description: 'with denser nesting, faster printing or easier support removal',
              lines: [
                {
                  copy: '2x',
                  type: 'bold'
                },
                {
                  copy: 'machine<br/>productivity',
                  type: ''
                }
              ]
            },
            {
              order: 'cols',
              description: 'for any scale, from small lab to large production center',
              lines: [
                {
                  copy: '100',
                  type: 'bold'
                },
                {
                  copy: '%<br/>ready',
                  type: ''
                }
              ]
            }
          ]}
        />

        <BackgroundHalfGrey>
          <HighlightBlockNoImage
            Theme={'Dark-Primary'}
            AdditionalDescription={
              'Oqcam provides automated dental workflows to help you meet growing production goals while significantly reducing manpower requirements. An end-to-end, integrated dental production system, Oqcam ensures full traceability to future-proof your business.<br/><br/>Our platform is agnostic, replacing multiple disconnected software applications, across all of your machines.'
            }
            Title={'<h3>Why Oqcam for Dental Production?</h3>'}
            Button={{
              copy: 'Download the brochure',
              SourceID: 'download-brochure',
              ModelTitle: 'Download the brochure',
              OpenDemoModel: true
            }}
          />
        </BackgroundHalfGrey>
      </BackgroundCircles>
      <BackgroundGrey>
        <AnchoredContent
          Title={'Applications'}
          Items={[
            {
              img: '/assets/images/dental/printing.png',
              anchor: 'Metal 3D Printing',
              title: 'Maximize Productivity of Complex Technology',
              copy: '<p>Smart automation saves up to 90% in data preparation time</p><p>Layered nesting of crowns & bridges, and high-density, interlocked nesting of RPDs</p><p>Optimized supports for easier support removal and reduced finishing</p>'
            },
            {
              img: '/assets/images/dental/production.png',
              anchor: 'Aligner Production',
              title: 'Connect and Automate your production',
              copy: '<p>Connecting design, 3D printing, thermoforming, laser marking, CNC trimming, labelling and packaging</p><p>Fully automated data preparation and optimized nesting for cost-effective production</p><p>Easily scalable, across multiple location, and ready for any volume</p>'
            },
            {
              img: '/assets/images/dental/milling.png',
              anchor: 'CNC Milling',
              title: 'Avoid Repetitive Tasks',
              copy: '<p>AI-driven automation of CAM workflows, including automatic stock selection, nesting, and toolpath generation</p><p>Machine learning capabilities for orientation and pin placement</p><p>Optimised machining efficiency and consistent results based on feature detection and automated assignment of milling templates.</p>',
              button: {
                label: 'Learn More',
                link: '/cnc/'
              }
            },
            {
              img: '/assets/images/dental/management.png',
              anchor: 'Production Management',
              title: 'Schedule, Track and Trace your entire production',
              copy: '<p>Prepare, plan, schedule, track and trace your entire production facilities</p><p>End-to-end traceability of materials, tools, parts and jobs with full revision history</p><p>Rich data can be gathered automatically by integrating and connecting CAM, MES and IoT</p>'
            }
          ]}
        />
      </BackgroundGrey>
      <SplitColumn
        ImagePosition={'Right'}
        Copy={'<h4>"Insane" cost and productivity improvements</h4><h5>Customer story - Crown Ceram</h5>'}
        Background={'Off-White'}
        MediaType={'Video'}
        Video={'https://play.vidyard.com/G8tWvS54bkCxUK4cNFaPfe?'}
        Button={{
          copy: 'Read Customer Story',
          theme: 'primary',
          link: '/posts/insane-cost-and-productivity-improvements/'
        }}
      />
      <ArticleBlock
        Title={'Customer Stories'}
        Layout={'layout-3'}
        Items={[
          {
            title: "Oqcam's innovative software multiplies our production efficiency",
            link: '/posts/oqcams-innovative-software-multiplies-our-production-efficiency/',
            image: '/assets/images/customerStories/ideas/IMG-1.png',
            category: 'Customer Story',
            readTime: '5 min. read'
          },
          {
            title: 'A “mind-blowing step forward” thanks to Oqcam',
            link: '/posts/a-mind-blowing-step-forward-thanks-to-oqcam/',
            image: '/assets/images/customerStories/teamziereis/img-1.png',
            category: 'Customer Story',
            readTime: ''
          },
          {
            title: 'Dentistry Without the Labor: The Journey to Automation',
            link: '/posts/dentistry-without-the-labor-the-journey-to-automation/',
            image: '/assets/images/customerStories/mclw/mclw_3.jpg',
            category: 'Customer Story',
            readTime: '8 min. read'
          }
        ]}
      />

      <BackgroundHalfGrey>
        <HighlightBlockNoImage
          Theme={'Dark-Primary'}
          Title={
            '<h3><b>Production efficiency with peace of mind?</b><br/>Connect with us for a personalised demo!</h3>'
          }
          Button={{
            copy: 'Request a Demo',
            OpenDemoModel: true,
            SourceID: 'request-demo'
          }}
        />
      </BackgroundHalfGrey>
      <BackgroundGrey>
        <ArticleBlock
          Title={'Dental Production Insights'}
          Link={{
            copy: 'All Insights',
            link: '#'
          }}
          Layout={'layout-2'}
          Items={[
            {
              title: 'How Automation Helps You Prepare Dental Metal 3D Printing Faster',
              link: '/posts/dental-automation/',
              image: '/assets/images/dental/dental.png',
              category: 'Webinar',
              readTime: '30 min. watch'
            },
            {
              title: 'How to connect your clear aligner production system.',
              link: '/posts/how-to-connect-your-clear-aligner-production-system/',
              image: 'https://app.dropinblog.com/uploaded/blogs/34247473/files/ClearAlignerNest.png',
              category: 'Blog',
              readTime: '6 min. read'
            },
            {
              title: 'How dental labs can increase productivity & lower costs with Oqcam',
              link: '/posts/how-dental-labs-can-increase-productivity-lower-costs-with-oqcam/',
              image: 'https://app.dropinblog.com/uploaded/blogs/34247473/files/WorfklowInMOS.png',
              category: 'Blog',
              readTime: '8 min. read'
            }
          ]}
        />
      </BackgroundGrey>

      <LogoGrid
        Title={'Trusted by industry partners'}
        Logos={[
          {
            src: '/assets/images/industry-partners/EOS.png',
            alt: 'EOS'
          },
          {
            src: '/assets/images/industry-partners/Renishaw.png',
            alt: 'Renishaw'
          },
          {
            src: '/assets/images/industry-partners/HP.png',
            alt: 'HP'
          },
          {
            src: '/assets/images/industry-partners/E-Plus-3D.png',
            alt: 'E-Plus-3D'
          },
          {
            src: '/assets/images/industry-partners/Labtrac.png',
            alt: 'Labtrac'
          },
          {
            src: '/assets/images/industry-partners/Trumpf.png',
            alt: 'Trumpf'
          },
          {
            src: '/assets/images/industry-partners/3DSystems.png',
            alt: '3DSystems'
          },
          {
            src: '/assets/images/industry-partners/dgshape.png',
            alt: 'DGShape'
          },
          {
            src: '/assets/images/industry-partners/Sisma.png',
            alt: 'Sisma'
          },
          {
            src: '/assets/images/industry-partners/DMGMori.png',
            alt: 'DMGMori'
          },
          {
            src: '/assets/images/industry-partners/SLMSolutions.png',
            alt: 'SLMSolutions'
          },
          {
            src: '/assets/images/industry-partners/nexa3D.png',
            alt: 'nexa3D'
          },
          {
            src: '/assets/images/industry-partners/imes-icore.png',
            alt: 'imes-icore'
          },
          {
            src: '/assets/images/industry-partners/PRODWAYS.png',
            alt: 'PRODWAYS'
          },
          {
            src: '/assets/images/industry-partners/Stratasys.png',
            alt: 'Stratasys'
          },
          {
            src: '/assets/images/industry-partners/2onelab.png',
            alt: '2onelab'
          },
          {
            src: '/assets/images/industry-partners/xact-metal-logo.png',
            alt: 'Xact Metal'
          }
        ]}
      />

      <FooterButtonOverlay
        Title={'What are you waiting for? Try for yourself now'}
        Button={{
          SourceID: 'request-trial',
          OpenDemoModel: true,
          Label: 'Request a free trial'
        }}
      />
      <Footer additionalClasses={'additional-top-padding'} />
    </PageContainer>
  );
};

export default Dental;
