import styled from 'styled-components';

import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const AnnoucementBarStyles = styled.div`

  margin-top: -25px;
  padding-bottom: 25px;
  position: relative;
  z-index: 1;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin-top: -55px;
    padding-bottom: 55px;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin-top: -87px;
    padding-bottom: 0;
  }

  &.offset-bottom{
    margin-bottom: -87px;
  }

  

`;

export const Inner = styled.div`
  background: white;
  border-radius: 6px;
  padding: 32px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  
  img{
    height: auto;
    width: auto;
    max-width: 250px;
    max-height: 75px;
    margin: 0 auto 32px;
    display: block;
  }
  
  .button-wrap{
    text-align: left;
    margin-top: 32px;
  }
  
  h3{
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    color: ${colors.primary};
    margin-bottom: 12px;
  }
  
  p{
    color: ${colors.primary};
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    color: ${colors.UILightSecondary};
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    div.text-wrap{
      padding: 0 32px;
        min-width: 500px;
    }

    .button-wrap{
      min-width: 170px;
      display: flex;
      text-align: right;
      justify-content: end;
      margin-top: 0;
    }


    img{
      width: auto;
      max-height: 80px;
      margin-bottom: 0;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    height: 150px;
    padding: 40px;
    img{
      width: auto;
      max-height: 95px;
      margin-bottom: 0;
    }
      div.text-wrap{
          padding: 0 32px;
          min-width: 750px;
      }

  }

`;

